/* eslint-disable max-len */
import * as React from 'react';
import { Link } from 'gatsby';
import cn from 'classnames';
import { Layout } from 'components/Layout';
import { SEO } from 'components/SEO';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { ContactUs } from 'components/ContactUs';
import delivery1Img from 'images/delivery1.jpg';
import retinaDelivery1Img from 'images/delivery1@2x.jpg';
import delivery2Img from 'images/delivery2.jpg';
import retinaDelivery2Img from 'images/delivery2@2x.jpg';
import delivery3Img from 'images/delivery3.jpg';
import retinaDelivery3Img from 'images/delivery3@2x.jpg';
import delivery4Img from 'images/delivery4.jpg';
import retinaDelivery4Img from 'images/delivery4@2x.jpg';
import delivery5Img from 'images/delivery5.jpg';
import retinaDelivery5Img from 'images/delivery5@2x.jpg';
import delivery6Img from 'images/delivery6.jpg';
import retinaDelivery6Img from 'images/delivery6@2x.jpg';
import delivery7Img from 'images/delivery7.jpg';
import retinaDelivery7Img from 'images/delivery7@2x.jpg';
import delivery8Img from 'images/delivery8.jpg';
import retinaDelivery8Img from 'images/delivery8@2x.jpg';
import delivery9Img from 'images/delivery9.jpg';
import retinaDelivery9Img from 'images/delivery9@2x.jpg';
import delivery10Img from 'images/delivery10.jpg';
import retinaDelivery10Img from 'images/delivery10@2x.jpg';
import s from './delivery.module.css';

type Item = {
  readonly title: string;
  readonly img: any;
  readonly retinaImg: any;
  desc: string;
};

const data: Item[] = [
  {
    title: 'Происхождение наших фруктов',
    img: delivery1Img,
    retinaImg: retinaDelivery1Img,
    desc:
      'Наши фрукты выращиваются в естественной среде происхождения под чутким присмотром садоводов: в Южной Америке, Африке и южной части Евразии. В микроклимате, приближенном к условиям тропиков, вбирают в себя необходимые витамины.',
  },
  {
    title: 'Сбор урожая',
    img: delivery2Img,
    retinaImg: retinaDelivery2Img,
    desc:
      'Мы заранее знаем объём урожая и готовимся к сбору. Ещё до доставки на наши склады мы знаем вкусовые качества и особенности урожая, что позволяет заблаговременно дать вам информацию о свойствах продуктов.',
  },
  {
    title: 'Транспортировка',
    img: delivery3Img,
    retinaImg: retinaDelivery3Img,
    desc:
      'Мы детально прорабатываем план транспортировки и тщательно контролируем процесс погрузки, чтобы избежать любых повреждений наших продуктов. Чтобы сохранить их свежими, мы перевозим их в специальных охлаждающих контейнерах.',
  },
  {
    title: 'Контроль качества',
    img: delivery4Img,
    retinaImg: retinaDelivery4Img,
    desc:
      'Все продукты обязательно проходят контроль качества перед продажей. Хотя мы и уверены в своих поставщиках, эта процедура позволяет полностью исключить попадание к клиенту некачественных продуктов.',
  },
  {
    title: 'Акклиматизация и созревание',
    img: delivery5Img,
    retinaImg: retinaDelivery5Img,
    desc:
      'После проверки качества мы выделяем время на акклиматизацию и созревание продуктов. Некоторые фрукты, такие как авокадо, требуют особого внимания на этой стадии. Под чутким присмотром наших сотрудников фрукты дозревают, чтобы попасть к вам в идеальном состоянии.',
  },
  {
    title: 'Сортировка',
    img: delivery6Img,
    retinaImg: retinaDelivery6Img,
    desc:
      'Дозревшие фрукты проходят этап тщательной ручной сортировки. Для вас мы отбираем вкусные и полезные фрукты с отличным внешним видом. Фрукты красивые настолько, что могут с легкостью участвовать в фотосессии.',
  },
  {
    title: 'Упаковка',
    img: delivery7Img,
    retinaImg: retinaDelivery7Img,
    desc:
      'Мы упаковываем фрукты согласно вашим пожеланиям. Биоразлагаемый пластик или эко — вы всегда можете выбрать. Мы составляем отчёт по каждой отгрузке и предоставляем все необходимые документы.',
  },
  {
    title: 'Доставка',
    img: delivery8Img,
    retinaImg: retinaDelivery8Img,
    desc:
      'Мы проверяем грузовики на соответствие температурным требованиям до и после отправки. Фрукты доедут в полной сохранности до ваших торговых точек.',
  },
  {
    title: 'Фрукты в вашем магазине',
    img: delivery9Img,
    retinaImg: retinaDelivery9Img,
    desc: 'После доставки фруктов мы консультируем вас по обработке, охлаждению и срокам годности товара.',
  },
  {
    title: 'Довольный клиент',
    img: delivery10Img,
    retinaImg: retinaDelivery10Img,
    desc:
      'Мы заботимся о том, чтобы наши клиенты могли купить самые свежие фрукты в любое время года. Пользуясь нашими услугами, вы можете быть уверены в высоком качестве продуктов.',
  },
];

const DeliveryPage = () => (
  <Layout>
    <SEO title="Доставка" />
    <div className="tr-bg-gray-2 py-16 mb-12 mlg:py-12 mmd:py-10 mmd:mb-10 msm:py-8 msm:mb-8">
      <div className="container flex flex-col items-center text-center">
        <h1 className={s.heroTitle}>Прямые поставки экзотических фруктов</h1>
        <div className={s.heroDescription}>
          История наших фруктов начинается на плантациях наших производителей. Там, под тропическим или субтропическим
          летним солнцем, они получают все время, питание и личное внимание, которое им нужно, чтобы расти от расцвета
          до совершенства.
        </div>
        <Link to="/contacts" className={cn('button button-normal button-yellow', s.heroButton)}>
          Узнать подробные условия
        </Link>
      </div>
    </div>
    <Breadcrumbs label="Доставка" className={s.breadcrumbs} />
    <div className={cn('container', s.items)}>
      {data.map((item) => (
        <div key={item.title} className={s.item}>
          <div className={s.itemContent}>
            <div className={s.itemTitle}>{item.title}</div>
            <div className={s.itemDescription}>
              <div>{item.desc}</div>
            </div>
          </div>
          <img src={item.img} srcSet={`${item.retinaImg} 2x`} alt="" className={s.itemImage} />
        </div>
      ))}
    </div>
    <ContactUs />
  </Layout>
);

export default DeliveryPage;
